
// eslint-disable-next-line import/no-named-as-default
/* eslint-disable new-cap */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
// import { gsap } from 'gsap'
import homeQuery from '~/apollo/queries/home'

export default {
  beforeRouteLeave (to, from, next) {
    this.resetColors()
    if (this.loop !== null) {
      clearInterval(this.loop)
    }
    next()
  },
  async asyncData ({ $graphql }) {
    const data = await $graphql.default.request(homeQuery)
    return {
      data: data.page
    }
  },
  data () {
    return {
      fields: {},
      tmp: [],
      loop: null,
      colorToUse: '#fff',
      loading: 0,
      resetted: 0,
      windowWidth: 1920,
      volume: false,
      marquee: null
    }
  },
  head () {
    return {
      title: this.metaTitle,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.metaDesc
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: 'https://studio375.it/media/logo-studio375.png'
        },
        {
          hid: 'og:image:width',
          property: 'og:image:width',
          content: '1200'
        },
        {
          hid: 'og:image:height',
          property: 'og:image:height',
          content: '630'
        },
        {
          hid: 'og:image:type',
          property: 'og:image:type',
          content: 'image/png'
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: this.metaTitle
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: 'https://studio375.it/'
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.metaDesc
        },
        {
          hid: 'og:locale',
          property: 'og:locale',
          content: 'it_IT'
        },
        {
          hid: 'og:site_name',
          property: 'og:site_name',
          content: 'Studio375'
        }
      ],
      bodyAttrs: {
        class: 'home'
      }
    }
  },
  computed: {
    colors () {
      return this.$store.state.colors
    },
    colorsCode () {
      return this.$store.state.colorsCode
    },
    classes () {
      return this.$store.state.classes
    },
    isAnim () {
      return this.$store.state.isAnim
    },
    metaDesc () {
      return this.$store.getters.getSeoByID(227).metaDesc
    },
    metaTitle () {
      return this.$store.getters.getSeoByID(227).title
    },
    // marquee () {
    //   return this.data.home_page.marquee
    // },
    posts () {
      return this.data.home_page.progetti
    }
  },
  mounted () {
    this.marquee = this.data.home_page.marquee
    const _this = this
    const windowWidth = window.innerWidth
    this.windowWidth = window.innerWidth
    this.$nextTick(function () {
      if (!this.isAnim) {
        setTimeout(function () {
          _this.setColors()
          _this.loopColors()
        }, 1000)
      }
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy () {
    this.resetColors()
    if (this.loop !== null) {
      clearInterval(this.loop)
    }
  },
  methods: {
    forcePlay () {
      if (this.$refs.videoHome !== undefined) {
        if (this.$refs.videoHome.currentTime > 0 && !this.$refs.videoHome.paused && !this.$refs.videoHome.ended) { this.$refs.videoHome.play() }
      }
    },
    toggleVolume () {
      if (this.$refs.videoHome !== undefined) {
        const muted = this.$refs.videoHome.muted
        if (muted) {
          this.$refs.videoHome.muted = false
          this.volume = true
        } else {
          this.$refs.videoHome.muted = true
          this.volume = false
        }
      }
    },
    onResize () {
      this.windowWidth = window.innerWidth
      // window.location.reload(false)
    },
    // initSinglePong () {
    //   const script = document.createElement('script')
    //   script.type = 'text/javascript'
    //   script.src = '/single_pong.js'
    //   document.head.appendChild(script)
    // },
    // initFullpage (time) {
    //   setTimeout(function () {
    //     const init = new fullpage('#fullpage', {
    //       licenseKey: null,
    //       scrollOverflow: true,
    //       paddingTop: '90px',
    //       responsiveWidth: 1023
    //     })
    //   }, time)
    // },
    // animLogo () {
    //   const tl = gsap.timeline()
    //   tl.to('.dark', { visibility: 'visible', width: '100%', ease: 'ease', duration: 1 })
    //     .to('#logo', { visibility: 'hidden', duration: 0 })
    //     .to('.dark', { borderRadius: '50%', duration: 0, width: '2000px', height: '2000px', left: 'calc(50% - 1000px)', top: 'calc(50% - 1000px)', ease: 'ease' })
    //     .to('.dark', { visibility: 'visible', borderRadius: '50%', width: '26px', height: '26px', left: 'calc(50% - 13px)', top: 'calc(50% - 13px)', ease: 'ease', duration: 1 })
    //     .to('.dark', { width: '0', height: '0', visibility: 'hidden', duration: 0, ease: 'ease' })
    //     .call(this.initSinglePong())

    //   const t2 = gsap.timeline()
    //   t2.to('.logoAnimated', { opacity: 1, scale: 1, duration: 1.4, ease: 'power4', delay: 1 })
    // },
    setColors () {
      this.resetColors()
      const boxes = document.querySelectorAll('.box:not(.not)').length
      const tmp = []
      let pos = null
      if (this.resetted && !this.apolloLoading) {
        for (let i = 0; i < this.colors.length; i++) {
          if (i < boxes) {
            do {
              pos = Math.floor(Math.random() * boxes)
            } while (tmp.includes(pos))
            tmp.push(pos)
            const box = document.querySelectorAll('.box:not(.not)')[pos]
            if (box !== undefined && box !== null) {
              box.classList.add(this.colors[i])
              box.classList.add(this.classes[i][0])
            }
          }
        }
        const _colors = this.colors
        const _classes = this.classes
        setTimeout(function () {
          for (let z = 0; z < _colors.length; z++) {
            const box2 = document.querySelectorAll('.box:not(.not)')[tmp[z]]
            if (box2 !== undefined && box2 !== null) {
              box2.classList.remove(_classes[z][0])
              const c = Math.floor(Math.random() * _colors.length)
              box2.classList.add(_classes[c][1])
            }
          }
        }, 2500)
      }
    },
    resetColors () {
      const boxes = document.querySelectorAll('.box:not(.not)')
      if (boxes && !this.apolloLoading) {
        for (let i = 0; i < boxes.length; i++) {
          for (let z = 0; z < this.classes.length; z++) {
            boxes[i].classList.remove(this.classes[z][1])
            boxes[i].classList.remove(this.classes[z][0])
          }
          for (let z = 0; z < this.colors.length; z++) {
            boxes[i].classList.remove(this.colors[z])
          }
          if (i === boxes.length - 1) {
            this.resetted = true
          }
        }
      }
    },
    loopColors () {
      this.loop = setInterval(() => {
        // eslint-disable-next-line no-undef
        if ($(document.body).hasClass('home')) {
          this.setColors()
        }
      }, 3500)
    }
  }
}
