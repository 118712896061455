import { render, staticRenderFns } from "./FooterMobile.vue?vue&type=template&id=0fb5b31c&scoped=true&"
import script from "./FooterMobile.vue?vue&type=script&lang=js&"
export * from "./FooterMobile.vue?vue&type=script&lang=js&"
import style0 from "./FooterMobile.vue?vue&type=style&index=0&id=0fb5b31c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fb5b31c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Footer: require('/builds/studio375/studio375-sito/components/Footer.vue').default})
