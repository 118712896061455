
export default {
  props: { link: { type: String, required: false, default: null }, linkLabel: { type: String, required: false, default: null }, back: { type: Boolean, required: false, default: null }, text: { type: String, required: false, default: null } },
  data () {
    return {
      windowWidth: 1920,
      privacyPolicyLink: ''
    }
  },
  head () {
    return {
      script: [
        // {
        //   src: 'https://cdn.iubenda.com/cs/iubenda_cs.js',
        //   charset: 'UTF-8',
        //   async: true
        // },
        // {
        //   src: '/iubenda.js'
        // }
      ]
    }
  },
  // watch: {
  //   '$route.path' () {
  //     this.$nextTick(
  //       setTimeout(() => {
  //         this.loadScript()
  //       }, 500)
  //     )
  //   }
  // },
  mounted () {
    this.windowWidth = window.innerWidth
    window.addEventListener('resize', this.onResize)
    this.privacyPolicyLink = `https://www.iubenda.com/privacy-policy/${window._iub.csConfiguration.cookiePolicyId}/full-legal`
    // setTimeout(() => {
    //   this.loadScript()
    // }, 500)
  },
  // beforeDestroy () {
  //   this.removeScript()
  // },
  methods: {
    onResize () {
      this.windowWidth = window.innerWidth
    },
    openPreferences () {
      window?._iub?.cs?.ui?.openPreferences()
    },
    loadScript () {
      let isFound = false
      const iubendaCs = '//cdn.iubenda.com/cs/iubenda_cs.js'

      const scripts = document.getElementsByTagName('script')
      for (let i = 0; i < scripts.length; ++i) {
        if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes(iubendaCs)) {
          isFound = true
        }
      }

      if (!isFound) {
        let node = document.createElement('script')
        node.dataset.myiub = 'myiub'
        node.type = 'text/javascript'
        node.innerText = 'var _iub = _iub || [];' +
            '_iub.csLoaded = false; ' +
            '_iub.csConfiguration = {' +
                '"consentOnContinuedBrowsing":false,' +
                '"countryDetection":true,' +
                '"gdprAppliesGlobally":false,' +
                '"invalidateConsentWithoutLog":true,' +
                '"perPurposeConsent":true,' +
                '"siteId":1151401,' +
                '"whitelabel":false,' +
                '"cookiePolicyId":37559116,' +
                '"lang":"it", ' +
                '"banner": { ' +
                '  "acceptButtonCaptionColor": "white",' +
                '  "acceptButtonColor": "#010101",' +
                '  "backgroundColor": "#ffffff",' +
                '  "customizeButtonCaptionColor": "white",' +
                '  "customizeButtonColor": "rgba(1, 1, 1, 0.4)",' +
                '  "acceptButtonDisplay":true,' +
                '  "closeButtonRejects":true,' +
                '  "customizeButtonDisplay":true,' +
                '  "explicitWithdrawal":true,' +
                '  "rejectButtonCaptionColor": "white",' +
                '  "rejectButtonColor": "#000000",' +
                '  "rejectButtonDisplay": true,' +
                '  "slideDown": true,' +
                '  "textColor": "#010101",' +
                '  "listPurposes":true,' +
                '  "position":"float-bottom-center" ' +
                '},' +
            '};'
        document.getElementsByTagName('head')[0].appendChild(node)

        node = document.createElement('script')
        node.src = iubendaCs
        node.type = 'text/javascript'
        node.async = true
        node.charset = 'utf-8'
        document.getElementsByTagName('head')[0].appendChild(node)
      }
    },
    removeScript () {
      const srcToDelete = [
        '//cdn.iubenda.com/cs/iubenda_cs.js',
        '//cdn.iubenda.com/cookie_solution/iubenda_cs/1.44.8/core-it.js'
      ]

      let scripts
      for (let x = 0; x < srcToDelete.length; x++) {
        scripts = document.getElementsByTagName('script')

        for (let i = 0; i < scripts.length; ++i) {
          if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes(srcToDelete[x])) {
            document.getElementsByTagName('head')[0].removeChild(scripts[i])
          }
        }
      }

      // Remove Custom Attribute
      scripts = document.getElementsByTagName('script')
      for (let i = 0; i < scripts.length; ++i) {
        if (scripts[i].hasAttribute('data-myiub')) {
          document.getElementsByTagName('head')[0].removeChild(scripts[i])
        }
      }
    }

  }
}
