import { render, staticRenderFns } from "./index.vue?vue&type=template&id=45cbddfb&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=45cbddfb&prod&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LogoStudioHome: require('/builds/studio375/studio375-sito/components/UI/LogoStudioHome.vue').default,MarqueeText: require('/builds/studio375/studio375-sito/components/MarqueeText.vue').default,Preview: require('/builds/studio375/studio375-sito/components/Posts/Preview.vue').default,Footer: require('/builds/studio375/studio375-sito/components/Footer.vue').default,FooterMobile: require('/builds/studio375/studio375-sito/components/FooterMobile.vue').default})
