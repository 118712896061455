
/* eslint-disable no-undef */
export default {
  props: { post: { type: Object, required: true, default: () => {} }, shared: { type: Boolean, required: false, default: true } },
  data () {
    return {
      hover: false,
      isHome: true
    }
  },
  computed: {
    title () {
      return this.post.title
    },
    slug () {
      return this.post.slug
    },
    excerpt () {
      return this.post.excerpt
    },
    preVideo () {
      // let url = (this.post.dettagli_post.videoCopertina !== null && this.post.dettagli_post.urlVideo !== null) ? this.post.dettagli_post.urlVideo : false
      // if (url) {
      //   const split = url.split('https://dna.studio375.it/wp-content')
      //   url = split[1]
      // }
      const url = (this.post.dettagli_post.videoCopertina !== null && this.post.dettagli_post.urlVideo !== null) ? this.post.dettagli_post.urlVideo : false
      return url
    },
    cliente () {
      return (this.post.clienti.edges[0]) ? this.post.clienti.edges[0].node.name : ''
    },
    categoria () {
      let ret = ''
      const cats = this.post.categories.edges
      let first = true
      for (let i = 0; i < cats.length; i++) {
        if (first) {
          ret += cats[i].node.name
          first = false
        } else {
          ret += ', ' + cats[i].node.name
        }
      }
      return ret
    },
    img () {
      // let url = (this.post.featuredImage !== null) ? this.post.featuredImage.node.sourceUrl : false
      // if (url) {
      //   const split = url.split('https://dna.studio375.it/wp-content')
      //   url = split[1]
      // }
      const url = (this.post.featuredImage !== null) ? this.post.featuredImage.node.sourceUrl : false
      return url
    },
    tags () {
      let ret = ''
      const tags = this.post.tags.edges
      let first = true
      for (let i = 0; i < tags.length; i++) {
        if (first) {
          ret += tags[i].node.name
          first = false
        } else {
          ret += ', ' + tags[i].node.name
        }
      }
      return ret
    },
    colors () {
      return this.$store.state.colorsCode
    },
    color () {
      return this.colors[Math.floor(Math.random() * this.colors.length)]
    }
  },
  methods: {
    stopVideo () {
      this.isHome = false
      if (this.$refs.v !== undefined) {
        this.$refs.v.pause()
      }
    },
    forcePlay () {
      if (this.isHome) {
        if (this.$refs.v !== undefined) {
          this.$refs.v.play()
        }
      }
    }
  }
}
